import React, { useCallback, useState, FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import styles from '@/common/styles/components/news-item.module.scss';
import { useRootSelector, useFilteredNews } from '@/common/hooks';
import {
  addReadContent,
  CollectInterests,
  collectInterestsLabels,
  CollectTopicContent,
  fetchPersonalState,
} from '@/common/store/slices/personal';
import {
  FEED,
  FIRST_PAGE,
  ITEM_PER_PAGE_RANKING_NEWS,
} from '@/common/constants';
import { ClickStatsWrapper } from '@/common/components/hoc';

interface Props {
  news: CollectTopicContent[] | undefined;
  popularity: CollectTopicContent[] | undefined;
}

/**
 * 내 피드에 있는 기사 랭킹 컴포넌트
 * @returns
 */
export const FeedNewsRanking: FC<Props> = memo(
  ({ news, popularity }: Props) => {
    const dispatch = useDispatch();
    const isLogin = useRootSelector((state) => state.rootSlice.isLogin);
    const filteredNews = useFilteredNews({
      news: news?.length ? news : popularity,
    });
    const [currentPage, setCurrentPage] = useState(0);

    const lastPage = Math.ceil(
      filteredNews.length / ITEM_PER_PAGE_RANKING_NEWS
    );
    const thumbnailNewsIndex = currentPage * ITEM_PER_PAGE_RANKING_NEWS;
    const nextThumbnailNewsIndex =
      (currentPage + 1) * ITEM_PER_PAGE_RANKING_NEWS;

    const prevPage = useCallback(() => {
      if (currentPage === FIRST_PAGE) setCurrentPage(lastPage - 1);
      else setCurrentPage(currentPage - 1);
    }, [currentPage, lastPage]);

    const nextPage = useCallback(() => {
      if (currentPage === lastPage - 1) setCurrentPage(0);
      else setCurrentPage(currentPage + 1);
    }, [currentPage, lastPage]);

    const readContent = useCallback(
      async (content: CollectTopicContent) => {
        if (isLogin) {
          await dispatch(addReadContent(content));
          await dispatch(fetchPersonalState());
        }
      },
      [isLogin, dispatch]
    );

    return (
      <section className={styles.ranking_news_wrap}>
        <h2 className="blind">뉴스</h2>

        {filteredNews.length >= 2 && (
          <>
            <div className={styles.ranking_news_slide}>
              <div className="thumb_news">
                <ClickStatsWrapper
                  event="@Click"
                  properties={{
                    pos: 'total_news',
                    selectTab: FEED,
                    order: 1,
                    page: currentPage + 1,
                    outurl: filteredNews[thumbnailNewsIndex].link,
                  }}
                >
                  <a
                    href={filteredNews[thumbnailNewsIndex].link}
                    target="_blank"
                    onClick={() => {
                      readContent(filteredNews[thumbnailNewsIndex]);
                    }}
                  >
                    <div className="thumb">
                      <img
                        loading="lazy"
                        src={filteredNews[thumbnailNewsIndex].thumbnail}
                        alt={filteredNews[thumbnailNewsIndex].title}
                      />
                    </div>
                    <ul className="info">
                      <li>
                        {
                          { ...collectInterestsLabels }[
                            filteredNews[thumbnailNewsIndex]
                              .subCategory as keyof CollectInterests
                          ]
                        }
                      </li>
                      <li>{filteredNews[thumbnailNewsIndex].origin}</li>
                    </ul>
                    <div className="title">
                      {filteredNews[thumbnailNewsIndex].title}
                    </div>
                  </a>
                </ClickStatsWrapper>
              </div>
              <div className="news_list">
                <ul>
                  {filteredNews
                    .slice(thumbnailNewsIndex + 1, nextThumbnailNewsIndex)
                    .map((article, index) => (
                      <li key={article.link}>
                        <ClickStatsWrapper
                          event="@Click"
                          properties={{
                            pos: 'total_news',
                            selectTab: FEED,
                            order: index + 3,
                            page:
                              currentPage + 1 < lastPage ? currentPage + 1 : 1,
                            outurl: article.link,
                          }}
                        >
                          <a
                            href={article.link}
                            target="_blank"
                            onClick={() => {
                              readContent(article);
                            }}
                          >
                            {article.title}
                          </a>
                        </ClickStatsWrapper>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className={styles.ranking_news_slide_nav}>
              <ClickStatsWrapper
                event="@Click"
                properties={{
                  pos: 'total_news',
                  selectTab: FEED,
                  cm: 'left',
                  page: currentPage + 1,
                }}
              >
                <button type="button" className="btn_prev" onClick={prevPage}>
                  이전
                </button>
              </ClickStatsWrapper>
              <ClickStatsWrapper
                event="@Click"
                properties={{
                  pos: 'total_news',
                  selectTab: FEED,
                  cm: 'right',
                  page: currentPage + 1,
                }}
              >
                <button type="button" className="btn_next" onClick={nextPage}>
                  다음
                </button>
              </ClickStatsWrapper>
            </div>
            <div className={styles.ranking_news_slide_page}>
              {Array.from({
                length: lastPage,
              }).map((_, page) => (
                <ClickStatsWrapper
                  event="@Click"
                  properties={{
                    pos: 'total_news',
                    selectTab: FEED,
                    cm: 'page',
                    page: page + 1,
                  }}
                  key={page}
                >
                  <button
                    type="button"
                    className={page === currentPage ? 'on' : ''}
                    onClick={() => {
                      setCurrentPage(page);
                    }}
                  >
                    {page + 1}
                  </button>
                </ClickStatsWrapper>
              ))}
            </div>
          </>
        )}
      </section>
    );
  }
);
