import React, { memo, useEffect, useRef } from 'react';

interface Props {
  id: string;
}

export const WiderAdCard = memo(({ id }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const runAd = (id: string) => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = `
    WiderPlanetAdRendererVar = {

      type : "iframe",
      passback : "PASSBACK_URL",
      category : "PAGE_CATEGORY",
      width : "294",
      height : "328",
      zoneid : "${id}"

    };`;
    ref.current?.appendChild(scriptElement);

    const scriptString =
      '<script src="//cdn-aitg.widerplanet.com/js/adr.js" type="text/javascript"></script>';

    import('postscribe').then((postscribe) => {
      if (ref.current) postscribe.default(ref.current, scriptString);
    });
  };

  useEffect(() => {
    runAd(id);
  }, [id]);

  return (
    <div id={`wpWidget_${id}`} ref={ref} style={{ width: 294, height: 328 }} />
  );
});
