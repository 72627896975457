import React from 'react';
import { NextPage } from 'next';
import { QueryClient, dehydrate } from 'react-query';

import { FeedNewsRanking } from '@/common/components/main/feed';
import { GoogleAdCards } from '@/common/components/main/ads';
import { CommonContents, CommonLoader } from '@/common/components/main/common';
import { useFilteredFeedContents, useHomeData } from '@/common/hooks';
import { ScrollViewStatsWrapper } from '@/common/components/hoc';
import { PAGE_NAMES } from '@/common/constants';
import { BannerCard } from '@/common/components/main/ads/BannnerCard';

const Feed: NextPage = () => {
  const { data, isLoading } = useHomeData();
  const filteredContents = useFilteredFeedContents({
    contents: data?.topicContents,
  });

  if (isLoading || !data) {
    return <CommonLoader />;
  }

  return (
    <>
      <ScrollViewStatsWrapper event={'@ScrollView'} scrollPos="row1">
        <FeedNewsRanking
          news={data?.topicContents.news}
          popularity={data?.topicContents.popularity}
        />
        <BannerCard />
        <GoogleAdCards />
      </ScrollViewStatsWrapper>

      {filteredContents.length ? (
        <CommonContents
          pageName={PAGE_NAMES.FEED}
          contents={filteredContents}
        />
      ) : (
        <CommonContents
          pageName={PAGE_NAMES.FEED}
          contents={data.topicContents.popularity}
        />
      )}
    </>
  );
};

// export async function getServerSideProps() {
//   const queryClient = new QueryClient();

//   await queryClient.prefetchQuery(
//     HOME_DATA_KEY,
//     externalApiService.fetchHomeData
//   );

//   return {
//     props: {
//       dehydratedState: dehydrate(queryClient),
//     },
//   };
// }

export default Feed;
