import React, { useEffect, useRef, memo } from 'react';

interface Props {
  id: string;
}

export const DableAdCard = memo(({ id }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const runAd = (d: Window, a: Document, b: string, l: string) => {
    if (!ref.current) return;

    if (d[b] && d[b].q) return;
    d[b] = function () {
      (d[b].q = d[b].q || []).push(arguments);
    };

    const element = a.createElement(l) as HTMLElement;
    element.async = 1;
    element.charset = 'utf-8';
    element.src = '//static.dable.io/dist/plugin.min.js';

    ref.current.parentNode?.insertBefore(element, ref.current);
  };

  useEffect(() => {
    runAd(window, document, 'dable', 'script');

    dable('setService', 'start.zum.com');
    dable('renderWidget', `dablewidget_${id}`, {
      ignore_items: true,
    });
  }, [id]);

  return <div id={`dablewidget_${id}`} data-widget_id={id} ref={ref} />;
});
