import React, { useEffect, useRef } from 'react';

interface Props {
  id: string;
}

export const EnlipleAdCard = ({ id }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const runAdScript = () => {
      const s = id,
        w = '294px',
        h = '328px',
        element = document.createElement('script');
      element.src =
        'https://native.mediacategory.com/servlet/adNative?from=' +
        escape(window.location.href.replace(window.location.hash, '')) +
        '&s=' +
        s +
        '&service=cntb&product=w&width=' +
        encodeURI(w) +
        '&height=' +
        encodeURI(h);
      element.type = 'text/javascript';

      ref.current?.appendChild(element);
    };

    const children = ref.current?.childNodes;

    if (children?.length) return;

    runAdScript();
  }, [id]);

  return <div id={`mobonDivBanner_${id}`} ref={ref} />;
};
