import React from 'react';
import { useRootSelector } from '@/common/hooks';
import styles from '@/common/styles/components/news-item.module.scss';
import { ClickStatsWrapper } from '@/common/components/hoc';
import bannerImg from '@/common/assets/images/img_hubzum_trip.png';

export const BannerCard = () => {
  const currentRoute = useRootSelector((state) => state.rootSlice.currentRoute);

  return (
    <>
      <section className={styles.news_single_item_small}>
        <ClickStatsWrapper
          event="@Click"
          properties={{
            pos: 'start_contents',
            selectTab: currentRoute,
            cm: 'item',
            outurl: 'https://hub.zum.com/trip',
            thumb: '',
          }}
        >
          <a href="https://hub.zum.com/trip" target="_blank">
            <div className="thumb">
              <img src={bannerImg.src} alt="허브줌 여행" loading="lazy" />
            </div>
            <ul className="info">
              <li>여행</li>
              <li>허브줌</li>
            </ul>
            <div className="title">
              대한민국에 이런 곳이 있었어? <br />
              핫한 여행지 다 알려 ZUM
            </div>
          </a>
        </ClickStatsWrapper>
      </section>
    </>
  );
};
