import { GOOGLE_AD } from '../../../constants';
import React, { useEffect } from 'react';
import styles from '@/common/styles/components/google-ad-card.module.scss';

declare global {
  interface Window {
    googletag?: any;
  }
}

interface Props {
  adUnitPath: string;
  containerId: string;
  width?: number;
  height?: number;
}

export const GoogleAdCard = ({
  adUnitPath,
  containerId,
  width = 300,
  height = 250,
}: Props) => {
  useEffect(() => {
    const runAdScript = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(function () {
        const slots = window.googletag.pubads().getSlots();

        // 탭 전환 후 내 피드로 돌아왔을 때 광고 슬롯이 등록되어 있는 상태로 리프레시 필요
        for (let slot of slots) {
          if (slot.getSlotElementId() === containerId) {
            window.googletag.pubads().refresh([slot]);
            return;
          }
        }

        window.googletag
          .defineSlot(adUnitPath, [width, height], containerId)
          .addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
        window.googletag.display(containerId);
      });
    };

    runAdScript();
  }, [adUnitPath, containerId, height, width]);

  return <div id={containerId} />;
};

export const GoogleAdCards = () => {
  return (
    <div className={styles.cards}>
      <GoogleAdCard
        adUnitPath={GOOGLE_AD.PATH_1}
        containerId={GOOGLE_AD.ID_1}
      />
      <GoogleAdCard
        adUnitPath={GOOGLE_AD.PATH_2}
        containerId={GOOGLE_AD.ID_2}
      />
    </div>
  );
};
